import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortColumn } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Draft, ProcedureMetadata, RedlinesMetadata } from 'shared/lib/types/views/procedures';
import { isReleased } from 'shared/lib/procedureUtil';
import { CancelFunc } from '../api/procedures';
import { selectOfflineInfo } from '../app/offline';
import RunBatchProcedureModal from '../components/BatchSteps/RunBatchProcedureModal';
import Button, { BUTTON_TYPES } from '../components/Button';
import { RowWithProjectName } from '../components/Home/GridExpandCollapseButton';
import HomeScreenTableRDG from '../components/Home/HomeScreenTableRDG';
import ListHeader, { Filter } from '../components/Home/ListHeader';
import { getColumns, getRows } from '../components/Home/procedureGridUtils';
import usePersistedView from '../components/Home/usePersistedView';
import ImportUploadModal from '../components/ImportUploadModal';
import NewProcedureModal from '../components/NewProcedureModal';
import { TabProps } from '../components/TabBar/TabBar';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useSettings } from '../contexts/SettingsContext';
import { useUserInfo } from '../contexts/UserContext';
import {
  DocSynced,
  selectProcedures,
  selectProceduresLoading,
  selectProceduresMetadata,
  selectProceduresSynced,
} from '../contexts/proceduresSlice';
import useLocationParams from '../hooks/useLocationParams';
import useMasterProcedureListHelpers from '../hooks/useMasterProcedureListHelpers';
import useProcedureListActions from '../hooks/useProcedureListActions';
import useProfile from '../hooks/useProfile';
import { PERM } from '../lib/auth';
import { CouchDBChanges } from '../lib/couchdbUtil';
import homeUtil from '../lib/homeUtil';
import REFRESH_TRY_AGAIN_MESSAGE from '../lib/messages';
import { procedureEditPath, proceduresPath } from '../lib/pathUtil';
import procedureUtil from '../lib/procedureUtil';
import projectUtil from '../lib/projectUtil';
import { getTagNames, getTagsAsOptions } from '../lib/tagsUtil';
import FlashMessage from '../components/FlashMessage';
import { ReactComponent as SparkleIcon } from '../images/sparkle.svg';
import Tooltip from '../elements/Tooltip';
import apm from '../lib/apm';

const emptyListText = 'No Procedures';
const PROCEDURES_KEY = 'master-procedure-list';
const ARCHIVED_PROCEDURES_KEY = 'archived-procedures';

const FILE_UPLOAD_SUCCESS = 'Upload successful. Notification will be sent when procedure is generated.';

const DEFAULT_SORT: Record<string, Array<SortColumn>> = {
  [PROCEDURES_KEY]: [
    {
      columnKey: 'procedureTitle',
      direction: 'ASC',
    },
  ],
  [ARCHIVED_PROCEDURES_KEY]: [
    {
      columnKey: 'archivedDate',
      direction: 'DESC',
    },
  ],
};

const ProcedureList = () => {
  const { auth } = useAuth();
  const hasEditPermission = useMemo(
    () => auth.hasPermission(PERM.PROCEDURES_EDIT) || auth.hasProjectsWithEditPermission(),
    [auth]
  );
  const dispatch = useDispatch();
  const { userInfo } = useUserInfo();
  const history = useHistory();
  const { mixpanel } = useMixpanel();
  const userId = userInfo.session.user_id;
  const { isOfflineProcedureDataEnabled } = useProfile();
  const isOnline = useSelector((state) => selectOfflineInfo(state).online);
  const { services, currentTeamId } = useDatabaseServices();
  const { syncMasterProcedureList } = useMasterProcedureListHelpers();
  const { showBatchRunModal, onStartRun, onStartBatchRun, onCancelBatchRun } = useProcedureListActions();

  const [redlinesMetadata, setRedlinesMetadata] = useState<Array<RedlinesMetadata>>([]);
  const [loading, setLoading] = useState(false);
  const procedures = useSelector((state) => selectProcedures(state, currentTeamId));
  const proceduresSynced = useSelector((state) => selectProceduresSynced(state, currentTeamId));
  const proceduresMetadata = useSelector((state) => selectProceduresMetadata(state, currentTeamId));
  const isProceduresLoading = useSelector((state) => selectProceduresLoading(state, currentTeamId));
  const { projects, tags, isAutomationUIEnabled, isProcedureGenerationEnabled } = useSettings();
  const [showNewModal, setShowNewModal] = useState(false);
  const [showImportUploadModal, setShowImportUploadModal] = useState(false);
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState<string | null>(null);

  const persistedView = usePersistedView();

  const [allProcedures, setAllProcedures] = useState<Array<ProcedureMetadata>>([]);

  const location = useLocation();
  const { hash } = useLocationParams(location);
  const navigatedSection = useMemo(() => {
    return hash === PROCEDURES_KEY || hash === ARCHIVED_PROCEDURES_KEY ? hash : PROCEDURES_KEY;
  }, [hash]);
  const [sortPreferenceByTab, setSortPreferenceByTab] = useState(DEFAULT_SORT);
  const sortPreference = useMemo<Array<SortColumn>>(() => {
    return sortPreferenceByTab[navigatedSection];
  }, [navigatedSection, sortPreferenceByTab]);

  const setSortPreference = useCallback(
    (newSortPreference: Array<SortColumn>) => {
      setSortPreferenceByTab((oldSortPreferenceByTab) => {
        return {
          ...oldSortPreferenceByTab,
          [navigatedSection]: newSortPreference,
        };
      });
    },
    [navigatedSection]
  );

  useEffect(() => {
    if (!services.procedures) {
      return;
    }

    setLoading(true);
    const refreshProcedures = async (changes: CouchDBChanges | null) => {
      await syncMasterProcedureList(changes);
      setLoading(false);
    };
    const refreshArchivedProcedures = () =>
      services.procedures.getAllProceduresMetadata().then((procedures: Array<ProcedureMetadata>) => {
        setAllProcedures(procedures);
        setLoading(false);
      });

    const getRedlinesMetadata = () =>
      services.procedures.getRedlinesMetadata().then((redlines) => {
        setRedlinesMetadata(redlines.rows);
      });

    let observer: CancelFunc;
    if (navigatedSection === PROCEDURES_KEY) {
      observer = services.procedures.onProceduresChanged(refreshProcedures);
      // Manually refresh the list on first load.
      refreshProcedures(null)
        .then(() => getRedlinesMetadata())
        .catch((err) => apm.captureError(err));
    } else if (navigatedSection === ARCHIVED_PROCEDURES_KEY) {
      observer = services.procedures.onProceduresChanged(refreshArchivedProcedures);
      refreshArchivedProcedures();
    }

    return () => {
      if (observer) {
        observer.cancel();
      }
    };
  }, [services, dispatch, currentTeamId, syncMasterProcedureList, navigatedSection]);

  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  const onUnarchive = useCallback(
    (procedure: ProcedureMetadata, procedureLink: string) => {
      services.procedures
        .unarchiveProcedure(procedure)
        .then(() => {
          history.push(procedureLink);
        })
        .catch(() => {
          /* Ignore */
        });
    },
    [history, services.procedures]
  );

  const onNewProcedure = useCallback(
    async (initalProcedure?: Draft) => {
      let persistedProcedure;
      try {
        // Generate procedure doc locally
        const procedureDoc = procedureUtil.newProcedure(userId, initalProcedure);
        // If the user is a project-only admin, set a project id to allow permissions to work
        if (auth.hasProjectOnlyEditPermissions() && !procedureDoc.project_id) {
          procedureDoc.project_id = auth.projectsWithEditPermission()[0];
        }
        // Try to persist to the backend
        persistedProcedure = await services.procedures.saveDraft(procedureDoc);
      } catch (error) {
        persistedView.setSaveError(REFRESH_TRY_AGAIN_MESSAGE);
        return;
      }
      mixpanelTrack('New Procedure');
      history.push(procedureEditPath(currentTeamId, persistedProcedure.procedure_id));
    },
    [mixpanelTrack, history, currentTeamId, userId, auth, services.procedures, persistedView]
  );

  const onClickNewProcedure = useCallback(() => {
    if (!isOnline) {
      persistedView.setSaveError(REFRESH_TRY_AGAIN_MESSAGE);
      return;
    }
    setShowNewModal(true);
  }, [isOnline, persistedView]);

  const onImportProcedureClicked = useCallback(() => {
    setShowImportUploadModal(true);
    mixpanelTrack('[Import Procedure] Upload modal opened');
  }, [mixpanelTrack]);

  const getFilteredRows = useCallback(
    (dataset: Array<ProcedureMetadata>, offlineSyncMap?: Record<string, DocSynced>) => {
      return getRows({
        procedures: dataset,
        searchTerm: persistedView.searchTerm,
        context: {
          projects,
          currentTeamId,
          proceduresSynced: offlineSyncMap,
          auth,
          isOfflineProcedureDataEnabled,
          redlinesMetadataMap: homeUtil.getRedlinesMetadataMap(redlinesMetadata),
          allProceduresMetadata: Object.entries(proceduresMetadata).map(([, procedure]) => procedure),
          onStartRun,
          onUnarchive,
        },
      });
    },
    [
      auth,
      currentTeamId,
      isOfflineProcedureDataEnabled,
      onStartRun,
      onUnarchive,
      proceduresMetadata,
      projects,
      redlinesMetadata,
      persistedView.searchTerm,
    ]
  );

  const activeRows = useMemo(
    () => getFilteredRows(procedureUtil.getMasterProcedureList(Object.values(proceduresMetadata)), proceduresSynced),
    [getFilteredRows, proceduresMetadata, proceduresSynced]
  );
  const archivedRows = useMemo(
    () => getFilteredRows(allProcedures.filter((p) => isReleased(p) && p.archived)),
    [allProcedures, getFilteredRows]
  );
  const rows = navigatedSection === PROCEDURES_KEY ? activeRows : archivedRows;

  const tagOptions = useMemo(() => getTagsAsOptions(tags), [tags]);
  const selectedTagNames = useMemo(
    () => getTagNames(persistedView.selectedTagKeys, tags),
    [persistedView.selectedTagKeys, tags]
  );

  const selectedProjectNames = useMemo(
    () => projectUtil.getProjectNames(persistedView.selectedProjectIds, projects),
    [projects, persistedView.selectedProjectIds]
  );

  const TABS: ReadonlyArray<TabProps<string>> = [
    { id: PROCEDURES_KEY, label: 'Active', count: homeUtil.tabCount(activeRows.length) },
    { id: ARCHIVED_PROCEDURES_KEY, label: 'Archived', count: homeUtil.tabCount(archivedRows.length) },
  ];

  const updateTab = useCallback(
    (tab: string) => {
      switch (tab) {
        case PROCEDURES_KEY:
          history.push(proceduresPath(currentTeamId));
          break;
        case ARCHIVED_PROCEDURES_KEY:
          history.push(proceduresPath(currentTeamId, ARCHIVED_PROCEDURES_KEY));
          break;
      }
    },
    [currentTeamId, history]
  );

  const headers = useMemo(() => {
    if (navigatedSection === PROCEDURES_KEY) {
      return getColumns({ state: 'Active' });
    } else if (navigatedSection === ARCHIVED_PROCEDURES_KEY) {
      return getColumns({ state: 'Archived' });
    }
    return [];
  }, [navigatedSection]);

  const onCloseUploadModal = (showUploadSuccessMessage: boolean) => {
    setShowImportUploadModal(false);
    if (showUploadSuccessMessage) {
      setUploadSuccessMessage(FILE_UPLOAD_SUCCESS);
    }
  };

  const isLoading = useMemo(() => {
    // Master procedures tab loading state is handled by redux proceduresSlice.
    if (navigatedSection === PROCEDURES_KEY) {
      return isProceduresLoading && loading;
    }

    return loading;
  }, [navigatedSection, loading, isProceduresLoading]);

  const actions = useMemo(() => {
    return navigatedSection === PROCEDURES_KEY && hasEditPermission ? (
      <div className="flex flex-row gap-x-1">
        {showImportUploadModal && <ImportUploadModal onClose={onCloseUploadModal} />}
        <FlashMessage message={uploadSuccessMessage} messageUpdater={setUploadSuccessMessage} />
        {isProcedureGenerationEnabled && isProcedureGenerationEnabled() && (
          <Tooltip content="Now powered by AI">
            <Button
              type={BUTTON_TYPES.TERTIARY}
              ariaLabel="Import Procedure"
              SvgIcon={SparkleIcon}
              onClick={onImportProcedureClicked}
              iconOnlyThreshold="lg"
            >
              Import Procedure
            </Button>
          </Tooltip>
        )}
        {(!isProcedureGenerationEnabled || !isProcedureGenerationEnabled()) && (
          <Button
            type={BUTTON_TYPES.TERTIARY}
            ariaLabel="Import Procedure"
            leadingIcon="upload"
            onClick={onImportProcedureClicked}
            iconOnlyThreshold="lg"
          >
            Import Procedure
          </Button>
        )}
        {showNewModal && (
          <NewProcedureModal
            isProjectClearable={!auth.hasProjectOnlyEditPermissions()}
            procedures={procedures}
            onNewProcedure={onNewProcedure}
            closeModal={() => setShowNewModal(false)}
            isAutomationUIEnabled={isAutomationUIEnabled()}
          />
        )}
        <Button type={BUTTON_TYPES.PRIMARY} onClick={onClickNewProcedure} title="New Procedure" leadingIcon="plus">
          New Procedure
        </Button>
      </div>
    ) : (
      <></>
    );
  }, [
    auth,
    hasEditPermission,
    isAutomationUIEnabled,
    navigatedSection,
    onClickNewProcedure,
    onImportProcedureClicked,
    onNewProcedure,
    procedures,
    showImportUploadModal,
    showNewModal,
    uploadSuccessMessage,
    isProcedureGenerationEnabled,
  ]);

  return (
    <div className="flex flex-col flex-grow px-5">
      <ListHeader
        isLoading={isLoading}
        persistedView={persistedView}
        filters={new Set([Filter.Projects, Filter.Tags])}
        tagOptions={tagOptions}
        name="Procedures"
        tabs={TABS}
        rows={rows as ReadonlyArray<RowWithProjectName>}
        navigatedSection={navigatedSection}
        updateTab={updateTab}
        actions={actions}
      />

      {showBatchRunModal && <RunBatchProcedureModal onRun={onStartBatchRun} onCancel={onCancelBatchRun} />}
      <HomeScreenTableRDG
        key={navigatedSection}
        headers={headers}
        rows={rows}
        emptyListText={emptyListText}
        searchTerm={persistedView.searchTerm}
        setSearchTerm={persistedView.setSearchTerm}
        projectNamesFilter={selectedProjectNames}
        tagNamesFilter={selectedTagNames}
        sortPreference={sortPreference}
        setSortPreference={setSortPreference}
        showParentChildRelation={false}
        viewTab={persistedView.viewTab}
        expandedProjectNames={persistedView.expandedProjectNames}
        setExpandedProjectNames={persistedView.setExpandedProjectNames}
      />
    </div>
  );
};

export default ProcedureList;
